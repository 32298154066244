<template>
  <div class="reservation-team-selection">
    <div class="reservation-team-selection--content">
      <TeamSelection
        ref="reservation-form-classifications"
        mode="edit"
        :existing-product-classification-id="productClassificationId"
        :existing-sourcing-team-classification-id="sourcingTeamClassificationId"
        :existing-support-team-classification-id="supportTeamClassificationId"
        :existing-tier-id="tierId"
        :disabled="isModeView"
        is-reservation-team-selection
        @product-input="changeClassification('product', $event)"
        @sourcing-team-input="changeClassification('sourcing-team', $event)"
        @support-team-input="changeClassification('support-team', $event)"
        @reservation-tier-input="changeReservationTier"
      />
      <v-divider />
    </div>
    <v-btn
      class="reservation-team-selection--action-btn"
      color="primary"
      style="border-radius: 0"
      :disabled="submitting"
      @click="submit"
    >
      Save
    </v-btn>
  </div>
</template>

<script>
import TeamSelection from '@/components/TeamSelection.vue'
import { EventBus } from '@/utils/event-bus'
import { updateClassifications, updateTier } from '@/services/reservations'
import { findPermissionByName } from '@/utils/permissions'
import { SplitFeatureFlag } from '@/utils/enum'
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'ReservationTeamSelection',
  components: {
    TeamSelection,
  },
  props: {
    reservationId: {
      type: Number,
      required: true,
    },
    quoteId: {
      type: Number,
      required: true,
    },
    existingProductClassificationId: {
      type: Number,
      default: null,
    },
    existingSourcingTeamClassificationId: {
      type: Number,
      default: null,
    },
    existingSupportTeamClassificationId: {
      type: Number,
      default: null,
    },
    existingTierId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      productClassificationId: null,
      sourcingTeamClassificationId: null,
      supportTeamClassificationId: null,
      tierId: null,
      isTiersEnabled: false,
    }
  },
  computed: {
    currentUserProfile() {
      return this.$store.getters['auth/currentUserProfile'] || {}
    },
    canEditTiers() {
      return findPermissionByName(
        this.currentUserProfile?.roles,
        'canEditTiers'
      )
    },
    tiers() {
      return this.getTiers()
    },
  },
  watch: {
    existingProductClassificationId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.productClassificationId = val
        }
      },
    },
    existingSourcingTeamClassificationId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.sourcingTeamClassificationId = val
        }
      },
    },
    existingSupportTeamClassificationId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.supportTeamClassificationId = val
        }
      },
    },
    existingTierId: {
      immediate: true,
      handler(val) {
        if (val) {
          this.tierId = val
        }
      },
    },
  },
  async mounted() {
    this.isTiersEnabled = await this.isFeatureEnabled(SplitFeatureFlag.ServiceTier)
  },
  methods: {
    ...mapGetters({
      getTiers: 'tiers/getTiers',
    }),
    ...mapActions({
      isFeatureEnabled: 'split/isFeatureEnabled',
    }),
    changeClassification(typeKey, classificationId) {
      if (typeKey === 'product') {
        this.productClassificationId = classificationId
      } else if (typeKey === 'sourcing-team') {
        this.sourcingTeamClassificationId = classificationId
      } else if (typeKey === 'support-team') {
        this.supportTeamClassificationId = classificationId
      }
    },
    changeReservationTier(tierId) {
      this.tierId = tierId
    },
    async submit() {
      this.submitting = true
      if (
        this.showTeamSelections &&
        !this.$refs['reservation-form-classifications'].validate()
      ) {
        this.submitting = false
        return
      }

      let classificationsPayload = {
        quoteId: this.quoteId,
        productClassificationId: this.productClassificationId,
        sourcingTeamClassificationId: this.sourcingTeamClassificationId,
        supportTeamClassificationId: this.supportTeamClassificationId,
      }

      const quoteResponse = await this.$store.dispatch(
        'quotes/updateConvertedQuote',
        classificationsPayload
      )

      delete classificationsPayload.quoteId
      classificationsPayload.reservationId = this.reservationId

      const reservationResponse = await updateClassifications(classificationsPayload)

      const tierPayload = this.getTierUpdatePayload()

      const responses = [quoteResponse, reservationResponse]

      if (this.isTiersEnabled && this.canEditTiers && tierPayload) {
        const tierResponse = await updateTier(tierPayload)
        responses.push(tierResponse)
      }

      EventBus.$emit('refresh-detail')
      await this.$store.dispatch('app/closeSidebarDialog')
      this.$emit('close')

      if (responses.some((r) => r.status !== 200)) {
        this.$store.dispatch(
          'app/showAlert',
          {
            message: 'Failed to update reservation classifications',
            color: 'error',
          },
          { root: true }
        )
      } else {
        this.$store.dispatch(
          'app/showAlert',
          {
            message: 'Reservation classifications updated',
            color: 'success',
          },
          { root: true }
        )
      }
    },
    getTierUpdatePayload() {
      const newTier = this.tiers.find((tier) => tier.tierId === this.tierId)
      if (!newTier || !newTier.key) {
        return null
      }
      return {
        reservationId: this.reservationId,
        newTierKey: newTier.key,
        propagateToQuote: true,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.reservation-team-selection {
  height: 100%;
  width: 500px;

  &--content {
    flex: 1;
    margin: 40px;
  }

  &--action-btn {
    display: flex;
    position: fixed;
    flex-direction: column;
    font-size: 18px;
    color: white;
    bottom: 0;
    width: inherit;
    height: 71px;
    padding: 24px 0;
    text-align: center;
    font-weight: bold;
    cursor: pointer;
    width: inherit;
    margin: 0;
  }
}
</style>
